import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/Auth.vue'

import store from '@/store'
import {AUTH_GET_USER} from "@/store/actions.type"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth,
  },
  {
    path: '/manager',
    component: () => import(/* webpackChunkName: "manager" */ '../views/Manager/Layout.vue'),
    children: [
      {
        path: '/',
        alias: '/manager/verifications',
        component: () => import(/* webpackChunkName: "manager-verifications" */ '../views/Manager/Verifications.vue'),
      },
      {
        path: 'verifications/add',
        component: () => import(/* webpackChunkName: "manager-verifications-add" */ '../views/Manager/Verification.vue'),
      },
      {
        path: 'verifications/edit/:id',
        props: true,
        component: () => import(/* webpackChunkName: "manager-verifications-edit" */ '../views/Manager/Verification.vue'),
      },
      {
        path: 'devices',
        component: () => import(/* webpackChunkName: "manager-devices" */ '../views/Manager/Devices.vue'),
      },
      {
        path: 'users/verifier',
        component: () => import(/* webpackChunkName: "manager-users-verifiers" */ '../views/Manager/Users/Verifier.vue'),
      },
      {
        path: 'users/company',
        component: () => import(/* webpackChunkName: "manager-users-company" */ '../views/Manager/Users/Company.vue'),
      },
      {
        path: 'users/coordinator',
        component: () => import('../views/Manager/Users/Coordinator.vue'),
      },
      {
        path: 'routes',
        component: () => import('../views/Manager/Routes.vue'),
      },
      {
        path: 'routes/:id',
        props: true,
        component: () => import('../views/Manager/Route.vue'),
      },
    ]
  },
  {
    path: '/worker',
    component: () => import(/* webpackChunkName: "worker" */ '../views/Worker/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "worker-verifications" */ '../views/Worker/Verifications.vue'),
      },
      {
        path: 'verifications',
        props: true,
        component: () => import('../views/Worker/VerificationList.vue'),
      },
      {
        path: 'verifications/edit/:id',
        props: true,
        component: () => import(/* webpackChunkName: "worker-verifications-edit" */ '../views/Worker/Verification.vue'),
      },
      {
        path: 'verifications/add',
        props: true,
        component: () => import(/* webpackChunkName: "worker-verifications-add" */ '../views/Worker/Verification.vue'),
      }
    ]
  },
  {
    path: '/mechanic',
    component: () => import(/* webpackChunkName: "worker" */ '../views/Mechanic/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "worker-verifications" */ '../views/Mechanic/Verifications.vue'),
      },
      {
        path: 'verifications/edit/:id',
        props: true,
        component: () => import(/* webpackChunkName: "worker-verifications-edit" */ '../views/Mechanic/Verification.vue'),
      },
      {
        path: 'verifications/add',
        props: true,
        component: () => import(/* webpackChunkName: "worker-verifications-add" */ '../views/Mechanic/Verification.vue'),
      }
    ]
  },
  {
    path: '/company',
    component: () => import(/* webpackChunkName: "company" */ '../views/Company/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import(/* webpackChunkName: "company-verifications" */ '../views/Company/Verifications.vue'),
      }
    ]
  },
  {
    path: '/coordinator',
    component: () => import('../views/Coordinator/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/Coordinator/Verifications.vue'),
      }
    ]
  },
  {
    path: '/filial_manager',
    component: () => import('../views/FilialManager/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/FilialManager/Home.vue'),
      },
      {
        path: 'verifications',
        component: () => import(/* webpackChunkName: "manager-verifications-add" */ '../views/FilialManager/Verifications.vue'),
      },
      {
        path: 'verifications/add',
        component: () => import(/* webpackChunkName: "manager-verifications-add" */ '../views/FilialManager/Verification.vue'),
      },
      {
        path: 'verifications/edit/:id',
        props: true,
        component: () => import(/* webpackChunkName: "manager-verifications-edit" */ '../views/FilialManager/Verification.vue'),
      },
      {
        path: 'users/verifier',
        component: () => import(/* webpackChunkName: "manager-users-verifiers" */ '../views/FilialManager/Users/Verifier.vue'),
      },
      {
        path: 'devices',
        component: () => import(/* webpackChunkName: "manager-devices" */ '../views/FilialManager/Devices.vue'),
      }
    ]
  },
  {
    path: '/director',
    component: () => import('../views/Director/Layout.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/Director/Home.vue'),
      },
      {
        path: 'managers',
        component: () => import('../views/Director/Users/Manager.vue'),
      },
      {
        path: 'filial_managers',
        component: () => import('../views/Director/FilialManages/Manager.vue'),
      },
      {
        path: 'companies',
        component: () => import(/* webpackChunkName: "manager-users-company" */ '../views/Director/Users/Company.vue'),
      },
      {
        path: 'coordinators',
        component: () => import('../views/Director/Users/Coordinator.vue'),
      },
      {
        path: 'prices',
        component: () => import('../views/Director/Prices/Price.vue'),
      },
      {
        path: 'incomes',
        component: () => import('../views/Director/IncomeExpenses/Index.vue'),
      },
      {
        path: 'verifications',
        component: () => import(/* webpackChunkName: "manager-verifications-add" */ '../views/Director/Verifications/Verifications.vue'),
      },
      {
        path: 'verifications/add',
        component: () => import(/* webpackChunkName: "manager-verifications-add" */ '../views/Director/Verifications/Verification.vue'),
      },
      {
        path: 'verifications/edit/:id',
        props: true,
        component: () => import(/* webpackChunkName: "manager-verifications-edit" */ '../views/Director/Verifications/Verification.vue'),
      },
      {
        path: 'meters',
        component: () => import('../views/Director/Meters/Meter.vue'),
      },
      {
        path: 'meter_brands',
        component: () => import('../views/Director/MeterBrands/Meter.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !store.getters['user'].id) {
    if (!store.getters['isAuthenticated']) {
      next('/')
    }

    store.dispatch(AUTH_GET_USER)
      .catch(() => next('/'))
      .then(() => next())
  } else {
    next()
  }
});

export default router
